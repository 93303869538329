<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :fab="fab"
    :small="small"
    :large="large"
    :color="computedButtonColorClass"
    :outlined="computedButtonOutlined"
    :text="buttonRecommendedTextStyle"
    :rounded="computedButtonRounded"
    :elevation="buttonElevation"
    :class="buttonRecommendedClass(spacingDirection.left)"
  >
    <!-- icon -->
    <slot name="icon">
      <v-icon v-if="hasSlot('icon-name')">
        <slot name="icon-name"></slot>
      </v-icon>
    </slot>

    <!-- text  -->
    <slot name="default" v-if="visibleText"></slot>
  </v-btn>
</template>

<script>
import { baseButtonMixin } from "@/mixins/shared/base/button/baseButtonMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "BaseButton",
  inheritAttrs: false,
  mixins: [baseButtonMixin, slottableMixin]
};
</script>
