// model
import { eventNames } from "@/model/common/events/eventConst";

// mixins
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";

export const baseButtonMixin = {
  mixins: [buttonSettingsMixin, reactiveMixin],
  data() {
    return {
      eventButtonClick: eventNames.click
    };
  },
  props: {
    /**
     * Button Icon and Text colorName
     */
    color: {
      type: String,
      default: undefined
    },

    /**
     * is large size
     */
    large: {
      type: Boolean,
      default: false
    },

    /**
     * is small size
     */
    small: {
      type: Boolean,
      default: false
    },

    /**
     * Designates the button as a floating-action-button. Button will become round
     */
    fab: {
      type: Boolean,
      default: false
    },

    /**
     * is round
     */
    round: {
      type: Boolean,
      default: false
    },

    /**
     * is outlined
     */
    outlined: {
      type: Boolean,
      default: false
    },

    /**
     * is button's text reactive, should it be visible On Sm And Up screen
     */
    reactiveText: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedButtonColorClass() {
      return this.color ? this.color : this.buttonColorClass;
    },
    computedButtonOutlined() {
      return this.outlined ? this.outlined : this.buttonOutlined;
    },
    computedButtonRounded() {
      return this.round ? this.round : this.buttonRounded;
    },
    computedTextStyle() {
      return this.computedButtonOutlined ? this.false : this.buttonTextStyle;
    },

    /**
     * compute visibility of button's Text
     * @return {boolean|boolean} true if button's Text is visible
     */
    visibleText() {
      return this.reactiveText ? this.visibleOnSmAndUp : true;
    }
  }
};
